@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@700&display=swap");

.header {
	background: linear-gradient(
		180deg,
		hsl(335, 100%, 45%) 90%,
		hsl(240, 100%, 20%)
	);

	margin-bottom: 0;
	padding: 0;
	text-align: center;
	top: 0;
	width: 100%;
}

.header-name {
	font-family: "Merriweather Sans", sans-serif;
	font-size: 24px;
	font-weight: 700;
	color: hsl(240, 100%, 20%);
}
