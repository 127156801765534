.login-image {
	background-image: url("./../../../public/assets/images/landing-page.jpg");
	height: 100vh;
	/* margin-top: -70px; */
	font-size: 50px;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.rds-image {
	background-image: url("./../../../public/assets/images/Real-Dev-Squad@2x.png");
	height: 70vh;
	width: 70vh;
	z-index: 1000000;
	background-size: contain;
}
.landing-page-buttons {
	display: flex;
	gap: 10rem;
	background-color: aliceblue;
	padding: 3rem;
	border-radius: 20px;

	box-shadow: 0px 1px 30px hsl(240, 100%, 20%);
}
.imagelogin {
	height: 12rem;
	width: 24rem;
}

.landing-button {
	border: 2px solid hsl(0, 0%, 0%);

	font-size: 20px;
	font-weight: bold;

	cursor: pointer;
}
.landing-button:hover {
	border: 2px solid hsl(0, 0%, 0%);
	padding: 1.5rem 4rem;

	background-color: hsla(60, 94%, 86%, 0.493);
	font-size: 20px;
	font-weight: bold;

	cursor: pointer;
}
.landing-button:active {
	border-radius: 10px;
	border: 2px solid hsl(0, 0%, 0%);
	background-color: beige;
	padding: 1.5rem 4rem;

	font-size: 20px;
	font-weight: bold;

	cursor: pointer;
}
