.footer {
	margin-top: 0;
	text-align: center;
	background-color: hsla(44, 100%, 47%, 0.9);
	padding: 0;
	position: fixed;
	bottom: 0;
	width: 100%;
}

.footer-text {
	font-size: 20px;
	font-weight: 400;
	color: hsl(0, 0%, 0%);
}
