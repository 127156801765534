.up-arrow {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;

	border-bottom: 20px solid white;
	position: absolute;
	top: -12px;
	right: 40%;
}
