@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
	@apply bg-white rounded overflow-hidden shadow-md relative;
}

.time-badge {
	@apply bg-secondary-100 text-secondary-200 font-bold text-xs uppercase rounded-full p-2 absolute top-0 ml-2 mt-2;
}

.btn {
	@apply rounded-full py-2 px-3 uppercase font-bold text-xs cursor-pointer tracking-wider;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: hsl(197, 24%, 94%);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
